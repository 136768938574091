import AwardBadges from 'components/globals/award-badges';

import './styles/awards.scss';

type AwardsProps = {
  category?: string,
  main_image_desktop: Url,
  main_image_mobile: Url,
  sub_title: string,
  title: string,
  content: any,
}

type Url = {
  url: SourceUrl
}

type SourceUrl = {
  source_url: string
}


const Awards = ({
  content,
  main_image_mobile,
  main_image_desktop,
  sub_title,
  title, 
  category = 'universal',
}:AwardsProps) => {
  return (
    <section
      className="awards-section section awards"
    >
      <div className="awards-container container columns is-flex">
        <div className="awards-globe-mobile-image is-hidden-tablet">
          <img
            loading="lazy"
            src={ main_image_mobile.url.source_url }
          ></img>
        </div>
        <div className="awards-globe-image is-hidden-mobile">
          <img
            loading="lazy"
            src={ main_image_desktop.url.source_url }
          ></img>
        </div>
        <div className="awards-content column">
          <h2 className="subheadline">{sub_title}</h2>
          <h3 className="headline">{title}</h3>
          <p
            className="body"
            dangerouslySetInnerHTML={ { __html: content } }
          />
          <AwardBadges filterCategory={ category } />
        </div>
      </div>
    </section>
  );
};

export default Awards;
