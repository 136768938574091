import { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';
import { createHistory, LocationProvider } from '@reach/router';

import windowCheck from 'utils/helpers/window-check';

import Footer from 'components/footer/default';
import { SignUpPlanIds } from 'components/forms/constants';
import Head from 'components/globals/head';
import { TippyTopBanner } from 'components/globals/tippy-top-banner';
import NavigationMain from 'components/navigation/main';

let history;
if (windowCheck) {
  history = createHistory(window as any);
}

ReactModal.setAppElement('#___gatsby');

type DefaultLayoutProps = {
  children: ReactNode
  customHubspotMeetingButtonText?: string,
  customHubspotMeetingUrl?: string,
  faq?: any,
  faqStructuredData?: string,
  footerButtonLink?: string,
  footerCtaTitle?: string,
  footerSignupButtonText?: string,
  footerSignupHeader?: string,
  hidden?: boolean,
  hideSignup?: boolean,
  hideSignupBox?: boolean,
  legalText?: string,
  modalTitle?: string,
  setIsFooterVisible?: (isVisible: boolean) => void,
  showInitFields?: boolean,
  showModalButton?: boolean,
  showScheduleDemoButton?: any,
  signUpRefPlanId?: SignUpPlanIds,
  signupModalSubmit?: string,
  tippyTopBanner?: any,
  trialLength?: number,
  useStaticFaq?: boolean
}

const Layout = ({    
  children,
  hidden,
  footerCtaTitle,
  faq,
  faqStructuredData,
  useStaticFaq,
  modalTitle,
  legalText,
  signupModalSubmit,
  hideSignup,
  hideSignupBox,
  tippyTopBanner,
  showScheduleDemoButton,
  showModalButton,
  footerButtonLink,
  showInitFields,
  footerSignupHeader,
  footerSignupButtonText,
  signUpRefPlanId,
  setIsFooterVisible,
  customHubspotMeetingUrl, 
  customHubspotMeetingButtonText,
  trialLength,
}:DefaultLayoutProps) => {

  const controlOverflow: any = () =>{
    return windowCheck && window.location.pathname === '/integrations' ? 'remove-overflow' : '';
  };

  return (
    <div className="layout-wrapper" style={ { visibility: hidden ? 'hidden' : 'visible' } }>
      <Head />
      <LocationProvider history={ history }>
        <ModalProvider>
          {tippyTopBanner && <TippyTopBanner { ...tippyTopBanner } />}
          <NavigationMain 
            customHubspotMeetingUrl={ customHubspotMeetingUrl } 
            trialLength={ trialLength } />
          <div role="main" className={ controlOverflow() }>{children}</div>
          <Footer
            footerCtaTitle={ footerCtaTitle }
            faq={ faq }
            faqStructuredData={ faqStructuredData }
            useStaticFaq={ useStaticFaq as boolean }
            signupModalSubmit={ signupModalSubmit }
            modalTitle={ modalTitle }
            showInitFields={ showInitFields === true || showInitFields === undefined ? true : false }
            hideSignup={ hideSignup as boolean }
            hideSignupBox={ hideSignupBox }
            legalText={ legalText }
            showModalButton={ showModalButton as boolean }
            customHubspotMeetingUrl={ customHubspotMeetingUrl }
            customHubspotMeetingButtonText={ customHubspotMeetingButtonText }
            showScheduleDemoButton={ showScheduleDemoButton }
            footerSignupHeader={ footerSignupHeader }
            footerButtonLink={ footerButtonLink }
            footerSignupButtonText={ footerSignupButtonText }
            signUpRefPlanId={ signUpRefPlanId }
            setIsFooterVisible={ setIsFooterVisible }
            trialLength={ trialLength }
          />
        </ModalProvider>
      </LocationProvider>
    </div>
  );
};

export default Layout;
