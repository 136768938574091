import ACFImage from 'types/ACFImage';

import './styles/payroll-integrations.scss';

type Props = {
  background_image_desktop: {
    source_url: string
  }
  background_image_mobile: {
    source_url: string
  }
  copy: string
  logos?: Logo[]
  logos_combined?: ACFImage
  sub_title: string
  title: string
}

type Logo = {
  logo: {
    alt_text: string
    source_url: string
  }
}

const PayrollIntegrations = (props:Props) => {
  return (
    <section className="payroll-integrations-section section">
      <div className="container">
        <h2 className="sub-title">{props.title}</h2>
        <h3 className="title">{props.sub_title}</h3>
        <p className="copy">{props.copy}</p>
        <div className="logo-list">
          {props.logos_combined && (
            <div className="logos-image-container">
              <img
                loading="lazy"
                src={ props.logos_combined.source_url }
                alt={ props.logos_combined.alt_text !== ''
                  ? props.logos_combined.alt_text
                  : 'When I Work integration partners'
                }
              />
            </div>
          )}
          {props.logos && (
            <ul>
              {props.logos.map((logo, index) => {
                return (
                  <li key={ index }>
                    <img
                      loading="lazy"
                      src={ logo.logo.source_url }
                      alt={ logo.logo.alt_text }
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {!!props.background_image_desktop ? (
        <picture className="hero-image">
          <source
            srcSet={ props.background_image_desktop?.source_url }
            media="(min-width: 425px)"
          />
          <img
            src={ props.background_image_mobile?.source_url }
            alt="When I Work"
          />
        </picture>
      ) : null}
    </section>
  );
};



export default PayrollIntegrations;
