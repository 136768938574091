
import FontIcon from 'components/icons';

import 'components/content-blocks/styles/features-list.scss';

type FeaturesListProps = {
    items: Items[];
    sub_title: string;
    title: string;
}

type Items = {
    body: string,
    desktop_image : {
        alt_text: string,
        source_url: string
    },
    icon : string,
    mobile_image : {
        alt_text: string,
        source_url: string
    },
    title: string
}

export const FeaturesList = ({
  title,
  sub_title,
  items,
}:FeaturesListProps) => {
  return (
    <section className="features-list-section section">
      <div className="has-text-centered features-list-intro">
        <h2 className="subheadline">{sub_title}</h2>
        <h3 className="headline">{title}</h3>
      </div>

      {items.map((item, index) => {
        return (
          <div key={ index } className="features-list">
            <div className="feature-body">
              <div className="feature-title">
                <FontIcon icon={ item.icon } />
                <h4>{item.title}</h4>
              </div>
              <p>{item.body}</p>
            </div>
            <picture>
              <source
                srcSet={ item.desktop_image.source_url }
                media="(min-width: 769px)"
              />
              <img
                loading="lazy"
                src={ item.mobile_image.source_url }
                alt={ item.mobile_image.alt_text }
              />
            </picture>
          </div>
        );
      })}
    </section>
  )
  ;
};