import Link from 'components/link/link';

import './styles/minimal-blog-articles.scss';

type MinimalBlogArticlesProps = {
  article: [],
  more_link: string,
  title: string,
}

type BlogArticle = {
  image: BlogImage,
  link: string,
}

type BlogImage = {
  alt_text: string,
  source_url: string,
}

const MinimalBlogArticles = ({
  title,
  more_link,
  article,
}:MinimalBlogArticlesProps) => (
  <section className="section minimal-blog-articles-section">
    <div className="container">
      <div className="columns">
        <div className="column is-12 has-text-centered">
          <h2 className="title">{title}</h2>
        </div>
      </div>
      <div className="minimal-article">
        {article.map((blogArticle: BlogArticle, index) => {
          return (
            <Link
              key={ index }
              className="minimal-article-item"
              to={ blogArticle.link }
            >
              {blogArticle.image.source_url && (
                <img
                  className="minimal-article-image"
                  src={ blogArticle.image.source_url }
                  alt={ blogArticle.image.alt_text }
                />
              )}
            </Link>
          );
        })}
      </div>
      <Link className="minimal-article-more" to={ more_link }>
        See Other Blog Articles
      </Link>
    </div>
  </section>
);

export default MinimalBlogArticles;