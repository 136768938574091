import { useEffect, useState } from 'react';

const IndustrySlides = ({ i, activeIndex, ...props }) => {
  const [isSlideVisible, setIsSlideVisible] = useState(
    props.hasOneSlideVisible === true ? 'is-hidden' : ''
  );
  useEffect(() => {
    if (props.hasOneSlideVisible === true) {
      if (activeIndex === i) {
        setIsSlideVisible('');
      } else {
        setIsSlideVisible('is-hidden');
      }
    }
  });
  return (
    <div className={ `review-wrapper ${isSlideVisible}` }>
      <div className="review-stats-desktop">
        <div className="stat-logo">
          <img
            loading="lazy"
            src={ props.logo?.source_url }
            alt={ props.logo?.alt_text }
          />
        </div>
        {props.stats.map((stat, index) => {
          return (
            <div key={ index } className="stat">
              <p className="stat-number">{stat.stat}</p>
              <p className="stat-label">{stat.text_label}</p>
            </div>
          );
        })}
      </div>
      <div className="review-details">
        <div className="review-logo">
          <div className="stat-logo mobile">
            <img
              loading="lazy"
              src={ props.logo?.source_url }
              alt={ props.logo?.alt_text }
            />
          </div>
        </div>
        <h4 className="review-title">{props.title}</h4>
        <div className="review-stats-mobile">
          {props.stats.map((stat, index) => {
            return (
              <div key={ index } className="stat mobile">
                <p className="stat-number">{stat.stat}</p>
                <p className="stat-label">{stat.text_label}</p>
              </div>
            );
          })}
        </div>
        <p className="review-text">{props.top_content}</p>
        <div className="review-quote">
          <div className="review-quote-content">
            <p className="review-quote-text">"{props.quote}"</p>
            <p className="review-quote-name">– {props.name} {props.position && `,  ${props.position}`}</p>
          </div>
          {props.customer_photo?.source_url && (
            <div className="review-quote-image">
              <img
                loading="lazy"
                src={ props.customer_photo?.source_url }
                alt={ props.customer_photo?.alt_text }
              />
            </div>)}

        </div>
        <p className="review-text">{props.bottom_content}</p>
        <div className="review-products">
          <h4 className="review-products-heading">Products Used</h4>
          <ul className="review-products-list">
            {props.products_used.map((product, index) => {
              return (
                <li key={ index } className="products-list-item">
                  <img
                    loading="lazy"
                    src={ product.product_icon.url.source_url }
                    alt={ product.product_icon.url.alt_text }
                    className="products-list-icon"
                  />
                  <div className="products-list-text">
                    {product.product_name}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IndustrySlides;
