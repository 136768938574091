import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { flatten } from 'lodash';

import IndustrySlides from './slides/industry-reviews';

type SliderIndustryReviewsProps = {
  activeIndex?: number,
  card?: any, 
  className?: string,
  desktop?: number,
  hasNoDots?: boolean,
  hasOneSlideVisible?: boolean,
  mobile?: number,
  modalTitle?: string,
  sliderStyle?: string,
  startingIndex?: number,

}

const Slider = (props:SliderIndustryReviewsProps) => {
  const [isSwipeView, setIsSwipeView] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);

  const breakpoints = useBreakpoint();

  const sliderStyle: any = props.sliderStyle
    ? props.sliderStyle
    : { padding: '0px calc(42% - 120px)' };

  const hasOneSlideVisible = props.hasOneSlideVisible || false;

  useEffect(() => {
    setIsSwipeView(breakpoints.sm);
  }, [breakpoints]);

  if (isSwipeView) {
    return (
      <div className={ `slider-container mobile slides swiper ${props.className ? props.className : ''}` }>
        <div className="slides-container">
          <SwipeableViews
            onChangeIndex={ i => setItemIndex(i) }
            style={ sliderStyle }
            slideStyle={ { padding: '0 10px' } }
            springConfig={ {
              easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
              duration: '.8s',
              delay: '0s',
            } }
            enableMouseEvents
            index={ props.startingIndex }
          >
            {props.card.map((card, index) => {
              return (
                <IndustrySlides
                  key={ `swipeableSlider${index}` }
                  { ...card }
                  i={ index }
                  activeIndex={ itemIndex }
                  hasOneSlideVisible={ hasOneSlideVisible }
                />
              );
            })
            }
          </SwipeableViews>
        </div>
        {props.hasNoDots ? (
          ''
        ) : (
          <div className="slides-dots">
            {props.card.length > 1 && new Array(flatten(props.card.map(card => card)).length)
              .fill(undefined)
              .map((_, index) =>
                index === itemIndex ? (
                  <div key={ index } className="slides-dot slides-dot-fill" />
                ) : (
                  <div key={ index } className="slides-dot slides-dot-empty" />
                )
              )}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={ `slider-container desktop slides ${props.className ? props.className : ''}` }>
        <div className="slides-container">
          {props.card.map((card, index) => {
            return (
              <IndustrySlides
                key={ `swipeableSlider${index}` }
                { ...card }
                i={ index }
                activeIndex={ props.activeIndex }
                hasOneSlideVisible={ hasOneSlideVisible }
              />
            );
          })}
        </div>
      </div>
    );
  }
};

export default Slider;
