import { Fragment } from 'react';
import { useModal } from 'react-modal-hook';

import Link from 'components/link/link';
import CustomCalendar from 'components/modal/custom-calendar';
import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';

import './styles/media-object.scss';
type MediaObjectProps = {
  body: string;
  button_text: string;
  button_url: string;
  className: string;
  custom_calendar_button: {
    button_text: string,
    button_url: string
  },
  custom_schedule_demo_url: string,
  desktop_image: {
    source_url: string;
  };
  is_schedule_button_primary: boolean,
  mobile_image: {
    source_url: string;
  };
  offset: string;
  schedule_demo: boolean;
  schedule_demo_text: string,
  sub_title: string;
  title: string;
  wysiwyg: string;
};

const MediaObject = ({
  sub_title,
  title,
  body,
  desktop_image,
  mobile_image,
  button_url,
  button_text,
  schedule_demo, 
  schedule_demo_text,
  custom_schedule_demo_url,
  is_schedule_button_primary,
  className,
  offset,
  wysiwyg,
  custom_calendar_button,
}:MediaObjectProps) => {
  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } customHubspotMeetingUrl={ custom_schedule_demo_url } />
  ));

  return (
    <section
      className={ `media-object-section section ${className || ''}${
        !!offset ? 'offset' : ''
      }` }
      data-section="mediaObject"
    >
      <div className="container">
        {!!offset ? (
          <div className="columns">
            <div className="column is-full-mobile is-half-tablet text">
              {sub_title && <h2 className="subheadline">{sub_title}</h2>}
              {title && <h3 className="headline">{title}</h3>}
              {body && <p className={ `body ${button_url ? 'has-button' : ''}` }>{body}</p>}
              {wysiwyg && <p dangerouslySetInnerHTML={ { __html: wysiwyg } } />}
              {button_url && button_url !== '' && (
                <Link className="button is-rounded" to={ button_url }>
                  {button_text}
                </Link>
              )}
            </div>
            <div className="column is-full-mobile is-half-tablet is-offset-1-desktop image">
              <picture className="image-container">
                <source
                  srcSet={ desktop_image.source_url }
                  media="(min-width: 992px)"
                />
                <img src={ mobile_image.source_url } alt="fill me out" />
              </picture>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="media-object-text">
              {sub_title && <h2 className="subheadline">{sub_title}</h2>}
              {title && <h3 className="headline">{title}</h3>}
              {body && <p
                className={ `body ${
                  button_url || schedule_demo ? 'has-button' : ''
                }` }
              >
                {body}
              </p>}
              {wysiwyg && <div dangerouslySetInnerHTML={ { __html: wysiwyg } } />}
              <div>
                {schedule_demo && (
                  <button
                    type="button"
                    className={ `button ${is_schedule_button_primary ? 'is-primary' : ''} is-rounded` }
                    onClick={ showScheduleDemoModal }
                  >
                    {schedule_demo_text}
                  </button>
                )}
                {button_url && button_url !== '' && (
                  <Link className="button is-rounded" to={ button_url }>
                    {button_text}
                  </Link>
                )}
              </div>
              {custom_calendar_button && <CustomCalendar buttonModalLaunchText={ custom_calendar_button.button_text } customCalendarUrl={ custom_calendar_button.button_url } />}
            </div>
            <picture className="image-container">
              <source
                srcSet={ desktop_image.source_url }
                media="(min-width: 992px)"
              />
              <img src={ mobile_image.source_url } role="presentation" />
            </picture>
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default MediaObject;
